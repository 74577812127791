import React from "react"
//import { Link } from "gatsby"

import styles from "./CompBlockCols.module.css"


class CompBlockCols extends React.Component {

  render() {

    return (

      <div className={`${styles.CompBlockCols}`}>
          {this.props.children}
      </div>

    )
  }
}

export default CompBlockCols